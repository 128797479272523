(function () {
    if (window.checkCookie("druzabni") == true) {
        window.fbAsyncInit = function () {
            FB.init({
                appId: '5805328152852544',
                xfbml: true,
                version: 'v8.0'
            });
            FB.AppEvents.logPageView();
        };

        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
                return;
            }
            js = d.createElement(s);
            js.id = id;
            js.src = "https://connect.facebook.net/sl_SI/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
    }
})()

export const facebook = () => {
    console.debug('facebook info: facebook.js');
    const consent = window?.Didomi?.getUserConsentStatusForVendor('facebook')
    if(!consent) return


    window.fbAsyncInit = function() {
        FB.init({
            appId            : 1043636120189168,
            xfbml            : true,
            version          : 'v18.0'
        });
    };
    var d = document,
    b = d.getElementsByTagName('body')[0],
    s = d.createElement('script'),
    dd = d.createElement('div');

    s.type = 'text/javascript'
    s.async = true;
    s.src = document.location.protocol + '//connect.facebook.net/sl_SI/sdk.js#xfbml=1&version=v17.0';
    s.defer = true;
    s.crossOrigin = 'anonymous';
    s.nonce = '0DUz6mZD';
    b.prepend(s);

    dd.id = 'fb-root'
    b.prepend(dd);

    // Facebook pixel
    const fbPixelId = '2332834930287570'
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', fbPixelId);
    fbq('track', 'PageView');
}