let removeMatomo = window.removeMatomo || new Function()

// const urlParams = new URLSearchParams(window.location.search)
// urlParams.get('pushpushgo')==='true' ? setTuji(true) : setTuji(false)

export const pushpushgo = () => {
    console.debug('pushpushgo: pushpushgo.js');
    let consent = window?.Didomi?.getUserConsentStatusForPurpose('third')
    // consent = urlParams.get('pushpushgo')==='true'

    if (consent) {
        const src = 'https://s-eu-1.pushpushgo.com/js/67daa2eefc1b87e0f737ebc8.js'
        var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
        g.async=true; g.src=src; g.classList.add("pushpushgo"); g.onload=removeMatomo;s.parentNode.insertBefore(g,s);
    }
}
