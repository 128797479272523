import { iprom_activator } from '../../../../src/es6/iprom_activator_func'
import settings from "../../../../src/es6/settings";


const ipromChannelsList = document.querySelector("#iprom_activator").dataset['iprom_channels_list'].split(',')

// iProm
const path=location.pathname;
const arrpath=path.split("/");
const iprom_server = 'iprom.net' // cdn.ipromcloud.com
// const iprom_server = 'ad.server.iprom.net'

console.log ("path=" + location.pathname);
console.log( "level1=" + arrpath[1] + "   level2=" + arrpath[2]);

const iprom_m="onaplus";
let SID="";
let SSID="";
if ( !arrpath[1] ) {
  SID="home";
} else {
  SID = arrpath[1];
  if (arrpath.length > 3) {
      if (arrpath.length > 4) {
          SSID = arrpath[2];
      }
      else if (arrpath.length == 4){
            const seElement = document.getElementById('SEID');
            if (!seElement) {
                SSID = arrpath[2];
            }
      }

  }
  if (arrpath[1] && arrpath[1] == "web-static") {
    SID = "static";
  }
  if (arrpath[1] && arrpath[1] == "podjetniske-zvezde") {
    SID = "delovepodjetnis";
  }
  if (arrpath[1] && ((arrpath[1] == "avtor") || (arrpath[1] == "tag") || arrpath[1] == "zadnje" || arrpath[1] == "search")) {
    SID = "vsezivo";
    SSID = "";
  }
}

SSID=SSID.replace(/[0-9]/g, '');
SSID=SSID.replace(/-/g, '');
SSID=SSID.substr(0,15);

SID=SID.replace(/[0-9]/g, '');
SID=SID.replace(/-/g, '');
SID=SID.substr(0,15);

console.log( "ipromads: SID = " + SID + "   SSID = " + SSID );


// var cc = window.checkCookie("oglasevalski");


(function(a,g,b,c){a[c]=a[c]||function(){
"undefined"===typeof a.ipromNS?(a[c].q=a[c].q||[]).push(arguments):a.ipromNS.execute(arguments)};
    var k=function(){var b=g.getElementsByTagName("script")[0];return function h(f){var e=f.shift();a[c]("setConfig",{server:e});
        var d=document.createElement("script");0<f.length&&(d.onerror=function(){a[c]("setConfig",{blocked:!0});h(f)});
        d.src="//cdn."+e+"/ipromNS.js";d.async=!0;d.defer=!0;b.parentNode.insertBefore(d,b)}}(),e=b;"string"===typeof b&&(e=[b]);k(e)
})(window, document,[ iprom_server ],"_ipromNS");

window.iprom_m = iprom_m;
window.SID = SID;
window.SSID = SSID;

// _ipromNS('init', {
//     sitePath      : ['onaplus', SID, SSID] ,
//     keywords      : ipromChannelsList,
//     cookieConsent : cc
// });
//
// iprom_activator()



export const iprom = () => {
    console.log('Iprom: Running iprom_activator.js')
    const consent = window.Didomi.getUserConsentStatusForVendor('c:iprom')
    if(!consent) return

    _ipromNS('init', {
        sitePath      : ['onaplus', SID, SSID] ,
        keywords      : ipromChannelsList,
        cookieConsent : consent
    })
    // console.log("Custom event triggered:", event.detail);
    iprom_activator()
}



